<template >
  <div>
    <div class="container-fluid m-0 p-0">
      <div class="row m-0 p-3">
        <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
          <div class="row m-0 p-3">
            <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
              <p class="font-1-2s font-600">Upload Attributes Eng</p>
            </div>
          </div>
          <div class="row m-0 p-0 w-100">
            <div class="col-12">
              <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
                <b-tab title="Upload" :title-link-class="linkClass(0)">
                  <UploadAttributes />
                </b-tab>
                <b-tab
                  title="Export Attributes"
                  :title-link-class="linkClass(1)"
                >
                  <ExportAttributes />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadAttributes from "../../../components/Manage/Attributes/UploadAttribute.vue";
import ExportAttributes from "../../../components/Manage/Attributes/ExportAttribute.vue";
export default {
  name: "UploadAttribute",
  components: {
    UploadAttributes,
    ExportAttributes,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script >
<style></style>


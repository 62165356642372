<template >
  <div class="col-12 mt-4 p-0 mb-3">
    <div class="row col-12">
      <div class="col-12 col-xl-2 mb-3">
        <div class="input-group-prepend input-group-sm">
          <select
            class="custom-select"
            v-model="mode"
            @change="ClearTablePimData()"
          >
            <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
              {{ e.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-xl-4 mb-3">
        <b-form-file
          accept=".xlsx , .xls"
          v-model="file1"
          :state="Boolean(file1)"
          size="sm"
          placeholder="เลือกไฟล์ที่ต้องการอัพโหลด"
          drop-placeholder="Drop file here..."
          @change="onChange"
        ></b-form-file>
      </div>
      <div class="col-12 col-xl-4 mb-2">
        <button
          type="button"
          class="btn btn-sm bt-bnb"
          @click="UploadAttribute()"
        >
          อัพโหลดข้อมูล
        </button>
        <button
          type="button"
          class="ml-1 btn-sm btn bt"
          @click="ClearTablePimData()"
        >
          ยกเลิก
        </button>
        <button type="button" class="ml-1 btn btn-sm bt-VFIX">
          <a
            :href="
              mode === 'Attribute'
                ? file_attribute
                : mode === 'AttributeGroup'
                ? file_attributeGroup
                : file_attributeOption
            "
            download
            class="text-white"
          >
            ไฟล์ตัวอย่าง {{ mode }}</a
          >
        </button>
      </div>
    </div>
    <div class="row mx-0 p-0">
      <div class="row col-12">
        <div class="col-12 col-xl-6">
          <p style="font-weight: bold">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
      </div>

      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="
            mode === 'Attribute'
              ? fields
              : mode === 'AttributeGroup'
              ? fields1
              : fields2
          "
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
        </b-table>
      </div>

      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import XLSX from "xlsx";
export default {
  name: "UploadAttribute",
  data() {
    return {
      file_attribute: require("@/files/AttributeEN_Examp.xlsx"),
      file_attributeGroup: require("@/files/AttributeGroupEN_Examp.xlsx"),
      file_attributeOption: require("@/files/AttributeOptionEN_Examp.xlsx"),
      file1: null,
      items: null,
      itemlist: null,
      totalRows: 0,
      page_size: 25,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      mode: "Attribute",
      select_mode: [
        { name: "Attribute", key: "Attribute" },
        { name: "AttributeGroup", key: "AttributeGroup" },
        { name: "AttributeOption", key: "AttributeOption" },
      ],
      fields: [
        { key: "attrId", label: "attrId", class: "text-left" },
        { key: "attrlabelTH", label: "attrlabelTH", class: "text-left" },
        { key: "attrlabelEN", label: "attrlabelEN", class: "text-left" },
        { key: "attrname", label: "attrname", class: "text-left" },
      ],
      fields1: [
        { key: "attrGroupId", label: "attrGroupId", class: "text-left" },
        { key: "catCode", label: "catCode", class: "text-left" },
        { key: "labelTH", label: "labelTH", class: "text-left" },
        { key: "labelEN", label: "labelEN", class: "text-left" },
      ],

      fields2: [
        { key: "id", label: "id", class: "text-left" },
        { key: "attrId", label: "attrId", class: "text-left" },
        { key: "labelTH", label: "labelTH", class: "text-left" },
        { key: "labelEN", label: "labelEN", class: "text-left" },
      ],
    };
  },
  mounted() {},
  methods: {
    async onChange(event) {
      try {
        this.file = event.target.files ? event.target.files[0] : null;
        let fname = this.file.name;
        fname = fname.toLowerCase();
        if (fname.indexOf(".xls") < 0)
          throw `กรุณาตรวจสอบ format ไฟล์!!! file  ${fname}  is not excel file (.xls or .xlsx).`;
        if (this.file) {
          const reader = new FileReader();
          if (this.mode === "Attribute") {
            reader.onload = (e) => {
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              let rows = XLSX.utils.sheet_to_row_object_array(ws);

              var jsnPimProductData = rows.map((row) => ({
                attrId: String(row.attrId).trim(),
                attrlabelTH: String(row.attrlabelTH).trim(),
                attrlabelEN: String(row.attrlabelEN).trim(),
                attrname: String(row.attrname).trim(),
              }));

              let findBlanks = jsnPimProductData.find(
                (itm) =>
                  itm.attrId == "" ||
                  itm.attrId == "undefined" ||
                  itm.attrId == "#VALUE!" ||
                  itm.attrlabelEN == "" ||
                  itm.attrlabelEN == "undefined" ||
                  itm.attrlabelEN == "#VALUE!" ||
                  itm.attrname == "" ||
                  itm.attrname == "undefined" ||
                  itm.attrname == "#VALUE!"
              );

              if (findBlanks) {
                this.$serviceMain.showErrorAlert(
                  this,
                  `กรุณาตรวจสอบไฟล์ พบค่าว่าง ค่า null ค่า #VALUE! ใน attrId, attrlabelEN, attrname `
                );
              }
              this.items = jsnPimProductData;
              this.itemlist = jsnPimProductData;
              this.totalRows = jsnPimProductData.length;
            };
            reader.readAsBinaryString(this.file);
          } else if (this.mode === "AttributeGroup") {
            reader.onload = (e) => {
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              let rows = XLSX.utils.sheet_to_row_object_array(ws);

              var jsnPimProductData = rows.map((row) => ({
                attrGroupId: String(row.attrGroupId).trim(),
                catCode: String(row.catCode).trim(),
                labelTH: String(row.labelTH).trim(),
                labelEN: String(row.labelEN).trim(),
              }));

              let findBlanks = jsnPimProductData.find(
                (itm) =>
                  itm.attrGroupId == "" ||
                  itm.attrGroupId == "undefined" ||
                  itm.attrGroupId == "#VALUE!" ||
                  itm.catCode == "" ||
                  itm.catCode == "undefined" ||
                  itm.catCode == "#VALUE!" ||
                  itm.labelEN == "" ||
                  itm.labelEN == "undefined" ||
                  itm.labelEN == "#VALUE!"
              );

              if (findBlanks) {
                this.$serviceMain.showErrorAlert(
                  this,
                  `กรุณาตรวจสอบไฟล์ พบค่าว่าง ค่า null ค่า #VALUE! ใน attrGroupId, catCode, labelEN `
                );
              }
              this.items = jsnPimProductData;
              this.itemlist = jsnPimProductData;
              this.totalRows = jsnPimProductData.length;
            };
            reader.readAsBinaryString(this.file);
          } else {
            reader.onload = (e) => {
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: "binary" });
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              let rows = XLSX.utils.sheet_to_row_object_array(ws);

              var jsnPimProductData = rows.map((row) => ({
                id: String(row.id).trim(),
                attrId: String(row.attrId).trim(),
                labelTH: String(row.labelTH).trim(),
                labelEN: String(row.labelEN).trim(),
              }));

              let findBlanks = jsnPimProductData.find(
                (itm) =>
                  itm.id == "" ||
                  itm.id == "undefined" ||
                  itm.id == "#VALUE!" ||
                  itm.attrId == "" ||
                  itm.attrId == "undefined" ||
                  itm.attrId == "#VALUE!" ||
                  itm.labelEN == "" ||
                  itm.labelEN == "undefined" ||
                  itm.labelEN == "#VALUE!"
              );

              if (findBlanks) {
                this.$serviceMain.showErrorAlert(
                  this,
                  `กรุณาตรวจสอบไฟล์ พบค่าว่าง ค่า null ค่า #VALUE! ใน id, attrId, labelEN`
                );
              }
              this.items = jsnPimProductData;
              this.itemlist = jsnPimProductData;
              this.totalRows = jsnPimProductData.length;
            };
            reader.readAsBinaryString(this.file);
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async ClearTablePimData() {
      this.file1 = null;
      this.items = null;
      this.itemlist = null;
      this.totalRows = 0;
    },
    async UploadAttribute() {
      try {
        if (this.items == null) throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`;
        const data = {
          jsnPimProductData: this.items,
          filename: this.file.name,
          type: this.mode,
        };
        console.log(data);
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `manage/uploadAttributesEng`,
          data,
          1
        );
        this.$serviceMain.showSuccessAlert(
          this,
          "อัพโหลดข้อมูลสำเร็จ / Complete"
        );
        // await this.$store.dispatch("UploadEnActions/getPimProductLanguage");
        await this.ClearTablePimData();
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script >

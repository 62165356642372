var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 mt-4 p-0 mb-3"},[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-12 col-xl-2 mb-3"},[_c('div',{staticClass:"input-group-prepend input-group-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mode),expression:"mode"}],staticClass:"custom-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.mode=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.ClearTablePimData()}]}},_vm._l((_vm.select_mode),function(e,i){return _c('option',{key:i,domProps:{"value":e.key}},[_vm._v(" "+_vm._s(e.name)+" ")])}),0)])]),_c('div',{staticClass:"col-12 col-xl-4 mb-3"},[_c('b-form-file',{attrs:{"accept":".xlsx , .xls","state":Boolean(_vm.file1),"size":"sm","placeholder":"เลือกไฟล์ที่ต้องการอัพโหลด","drop-placeholder":"Drop file here..."},on:{"change":_vm.onChange},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}})],1),_c('div',{staticClass:"col-12 col-xl-4 mb-2"},[_c('button',{staticClass:"btn btn-sm bt-bnb",attrs:{"type":"button"},on:{"click":function($event){return _vm.UploadAttribute()}}},[_vm._v(" อัพโหลดข้อมูล ")]),_c('button',{staticClass:"ml-1 btn-sm btn bt",attrs:{"type":"button"},on:{"click":function($event){return _vm.ClearTablePimData()}}},[_vm._v(" ยกเลิก ")]),_c('button',{staticClass:"ml-1 btn btn-sm bt-VFIX",attrs:{"type":"button"}},[_c('a',{staticClass:"text-white",attrs:{"href":_vm.mode === 'Attribute'
              ? _vm.file_attribute
              : _vm.mode === 'AttributeGroup'
              ? _vm.file_attributeGroup
              : _vm.file_attributeOption,"download":""}},[_vm._v(" ไฟล์ตัวอย่าง "+_vm._s(_vm.mode))])])])]),_c('div',{staticClass:"row mx-0 p-0"},[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" จำนวน  "),_c('span',{staticClass:"text-main"},[_vm._v(_vm._s(new Intl.NumberFormat().format(_vm.totalRows)))]),_vm._v("  รายการ ")])])]),_c('div',{staticClass:"col-12"},[_c('b-table',{staticClass:"font-0-8s",attrs:{"empty-text":'ไม่พบข้อมูล / No Data',"fields":_vm.mode === 'Attribute'
            ? _vm.fields
            : _vm.mode === 'AttributeGroup'
            ? _vm.fields1
            : _vm.fields2,"items":_vm.items,"current-page":_vm.page_num,"per-page":_vm.page_size,"outlined":"","hover":"","show-empty":"","responsive":""}})],1),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-6 col-xl-1 input-group-prepend input-group-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.page_size),expression:"page_size"}],staticClass:"custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.page_size=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.page_size_list),function(e,i){return _c('option',{key:i,domProps:{"value":e}},[_vm._v(" "+_vm._s(e)+" ")])}),0)]),_c('div',{staticClass:"col-6 col-xl-3"},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.page_size,"align":"fill","size":"sm"},model:{value:(_vm.page_num),callback:function ($$v) {_vm.page_num=$$v},expression:"page_num"}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
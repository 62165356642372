<template>
  <div>
    <div class="container-fluid m-0 p-0">
      <div class="row col-12 m-0 p-0">
        <div class="col-12 col-xl-6">
          <label>Attribute Type</label>
          <div class="input-group-prepend input-group-sm">
            <select
              class="custom-select"
              v-model="mode"
              @change="get_table_attrGroupId()"
            >
              <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                {{ e.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- <button @click="exportToExcel()">few</button> -->

        <div class="col-12 col-xl-6" v-show="mode === 'AttributeOption'">
          <label>Attribute Group</label>
          <cool-select
            v-model="data.attrGroupId"
            :items="option_attrGroupId"
            :placeholder="data.attrGroupId ? '' : 'All'"
            item-value="attrGroupId"
            item-text="labelName"
            class="IZ-select-sm"
          >
            <!-- slot for each item in the menu -->
            <template slot="item" slot-scope="{ item: e }">
              <div style="display: flex; align-items: center">
                <div>
                  {{ e.labelName }}
                </div>
              </div>
            </template>
            <!-- slot for the selected item (in the text field) -->
            <template slot="selection" slot-scope="{ item: e }">
              <div>
                {{ e.labelName }}
              </div>
            </template>
          </cool-select>
        </div>
      </div>
    </div>
    <div class="row mx-0 p-0 mt-3">
      <div class="row col-12">
        <div class="col-12 col-xl-6">
          <p style="font-weight: bold">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
        <span style="flex: 1; text-align: right; white-space: nowrap"
          ><button class="btn btn-sm bt-bnb" @click="exportToExcel()">
            นำออกข้อมูล <span v-html="$svg_icon.excel"></span></button
        ></span>

        <div class="col-12">
          <b-table
            :empty-text="'ไม่พบข้อมูล / No Data'"
            :fields="
              mode === 'Attribute'
                ? fields
                : mode === 'AttributeGroup'
                ? fields1
                : fields2
            "
            :items="
              mode === 'Attribute'
                ? items1
                : mode === 'AttributeGroup'
                ? items2
                : items3
            "
            :current-page="page_num"
            :per-page="page_size"
            outlined
            hover
            show-empty
            responsive
            class="font-0-8s"
          >
          </b-table>
        </div>
        <div class="col-12">
          <div class="row justify-content-end">
            <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
              <select class="custom-select" v-model="page_size">
                <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                  {{ e }}
                </option>
              </select>
            </div>
            <div class="col-6 col-xl-3">
              <b-pagination
                v-model="page_num"
                :total-rows="totalRows"
                :per-page="page_size"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CoolSelect } from "vue-cool-select";
import XLSX from "xlsx";
export default {
  name: "ExportAttribute",
  components: {
    CoolSelect,
  },
  data() {
    return {
      data: {
        attrGroupId: "",
        type: "",
        attrId: "",
      },
      option_attrGroupId: [],
      callAPI: false,
      items1: null,
      items2: null,
      items3: null,
      totalRows: 0,
      page_size: 25,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      mode: "Attribute",
      select_mode: [
        { name: "Attribute", key: "Attribute" },
        { name: "Attribute Group", key: "AttributeGroup" },
        { name: "Attribute Option", key: "AttributeOption" },
      ],
      fields: [
        { key: "attrId", label: "attrId", class: "text-left" },
        { key: "attrlabelTH", label: "attrlabelTH", class: "text-left" },
        { key: "attrlabelEN", label: "attrlabelEN", class: "text-left" },
        { key: "attrname", label: "attrname", class: "text-left" },
      ],
      fields1: [
        { key: "attrGroupId", label: "attrGroupId", class: "text-left" },
        { key: "catCode", label: "catCode", class: "text-left" },
        { key: "labelTH", label: "labelTH", class: "text-left" },
        { key: "labelEN", label: "labelEN", class: "text-left" },
      ],

      fields2: [
        { key: "id", label: "id", class: "text-left" },
        { key: "attrId", label: "attrId", class: "text-left" },
        { key: "labelTH", label: "labelTH", class: "text-left" },
        { key: "labelEN", label: "labelEN", class: "text-left" },
      ],
    };
  },
  watch: {
    "data.attrGroupId": function (v) {
      if (!!!v) {
        this.data.attrGroupId = "";
      }
      this.get_table_attrGroupId();
    },
  },
  mounted() {
    this.get_option_attrGroupId();
    this.get_table_attrGroupId();
  },
  methods: {
    async get_option_attrGroupId() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_groups_list`,
          {},
          1
        );
        this.option_attrGroupId = getAPI.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_table_attrGroupId() {
      try {
        this.data.type = this.mode;
        this.data.attrId = this.data.attrGroupId;
        if (this.mode === "Attribute" && this.items1 === null) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/get_table_attrGroupId`,
            this.data,
            1
          );
          this.items1 = getAPI.data;
          this.totalRows = this.items1.length;
        } else if (this.mode === "AttributeGroup" && this.items2 === null) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/get_table_attrGroupId`,
            this.data,
            1
          );
          this.items2 = getAPI.data;
          this.totalRows = this.items2.length;
        } else if (
          this.mode === "AttributeOption" &&
          this.data.attrGroupId !== ""
        ) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/get_table_attrGroupId`,
            this.data,
            1
          );
          this.items3 = getAPI.data;
          this.totalRows = this.items3.length;
        } else {
          this.mode === "Attribute"
            ? (this.totalRows = this.items1.length)
            : this.mode === "AttributeGroup"
            ? (this.totalRows = this.items2.length)
            : (this.totalRows = 0);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async exportToExcel() {
      var sheetExcel = XLSX.utils.json_to_sheet(
        this.mode === "Attribute"
          ? this.items1
          : this.mode === "AttributeGroup"
          ? this.items2
          : this.items3
      );

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, sheetExcel, `${this.mode}_data`); // invoices list is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, `${this.mode}_data.xlsx`);
    },
  },
};
</script>

